import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./App.css";
import geoverseLogo from "./images/gv-img.png";
import lineImg from "./images/line-img.svg";
import geoverseImg from "./images/geoverse-logo.png";
import SuccessModal from "./SuccessPopUp";
import ErrorModal from "./errorPopUp";

const App = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const onSubmit = (data) => {
    console.log("data", data);
    const url = `https://api-geotrade.geoverse.io:443/gv/geotrade/api/iam/contact-form/create`;
    fetchApi(url, "POST", data);
  };

  const fetchApi = (apiUrl, apiMethod, payload = null) => {
    //http://api-gateway.34.150.233.24.nip.io:80
    //https://stage-nextgen-api-geotrade.geoverse.io
    //https://vault-geotrade.geoverse.io/
    //https://api-geotrade.geoverse.io:443
    const header = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Methods": "*",
    };

    const payloadData = {
      contactNo: "",
      remarks: "",
      email: payload.email,
      name: payload.username,
    };

    const requestOptions = {
      method: apiMethod,
      headers: header,
      body: JSON.stringify(payloadData),
    };

    fetch(apiUrl, requestOptions)
      .then((res) => res.json())
      .then(
        (data) => {
          if (data.status === 201) {
            setShowModal(true);
            document.getElementById("trigger-popup").click();
          }
        },
        (error) => {
          setShowErrorModal(true);
          setMessage(error);
          document.getElementById("trigger-popup").click();
        }
      );
  };
  return (
    <div>
      <div className="login-container">
        <div className="login-header">
          <img src={geoverseImg} alt="login-img" />
        </div>
        <div className="login-area">
          <div className="login-card">
            <div className="logo-container">
              <img src={geoverseLogo} className="logo-img" alt="gv-img" />
              <p className="login-heading m-0">
                We have upgraded the GeoTrade Experience
              </p>
            </div>
            <div className="login-content">
              <p className="side-header">
                To experience our new portal, please give us the following
                details
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label className="label">NAME</label>
                  <input
                    type="text"
                    className="label-input"
                    {...register("username", {
                      required: true,
                      minLength: 3,
                      maxLength: 50,
                    })}
                  />
                  {errors?.username && errors?.username?.type === "required" && (
                    <small className="invalid-input" style={{ color: "red" }}>
                      Name is required
                    </small>
                  )}
                  {errors?.username && errors?.username?.type === "minLength" && (
                    <small className="invalid-input" style={{ color: "red" }}>
                      Min 3 characters required
                    </small>
                  )}
                  {errors?.username && errors?.username?.type === "maxLength" && (
                    <small className="invalid-input" style={{ color: "red" }}>
                      Max length exceeded
                    </small>
                  )}
                </div>
                <div className="form-group">
                  <label className="label">EMAIL</label>
                  <input
                    type="text"
                    className="label-input"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                    })}
                  />
                  {errors.email && errors?.email?.type === "required" && (
                    <small className="invalid-input" style={{ color: "red" }}>
                      Email is required
                    </small>
                  )}
                  {errors?.email && errors?.email?.type === "pattern" && (
                    <small className="invalid-input" style={{ color: "red" }}>
                      Please enter a valid email
                    </small>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn submit-btn"
                  data-toggle="modal"
                >
                  SUBMIT
                </button>
              </form>
              <button
                type="button"
                id="trigger-popup"
                hidden={true}
                data-toggle="modal"
                data-target="#staticBackdrop"
              ></button>
              <div className="d-flex justify-content-between align-items-center">
                <img className="line-img" src={lineImg} alt="line-img" />
                <a
                  type="button"
                  className="footer-text"
                  href="https://nextgen-geotrade.geoverse.io/"
                >
                  Already upgraded your account? Login here!
                </a>
                <img className="line-img" src={lineImg} alt="line-img" />
              </div>
            </div>
          </div>
        </div>
        <div className="login-bg"></div>
        {showModal && (
          <SuccessModal setShowModal={setShowModal} resetForm={reset} />
        )}
        {showErrorModal && (
          <ErrorModal setShowErrorModal={setShowErrorModal} message={message} />
        )}
      </div>
    </div>
  );
};

export default App;
