import React from 'react';
import "./App.css";
import closeIcon from "./images/close-icon.svg";
import gvWarningImage from './images/gv-Warning.svg';

const ErrorModal=(props)=> {
    const {setShowErrorModal,setEmail,setUsername} = props;
    const clear =()=>{
        setShowErrorModal(false);
        setEmail('');
        setUsername('');
    }

  return (
    <div>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered popup-dialog">
          <div className="modal-content popup-content">
            <div className="modal-body p-0">
              <div className="d-flex justify-content-end pb-2">
              <button type='button' data-dismiss="modal" className="close-btn">
                <img src={closeIcon} className="close-icon" alt="close-img" onClick={clear}/>
              </button>
              </div>
              <div className="pb-4 d-flex justify-content-center">
                <img src={gvWarningImage} alt="img" />
              </div>
              <p className="modal-heading">Error</p>
              <p className="modal-text">{props.message}</p>
            </div>
            <div className="d-flex justify-content-center pb-4">
           <button type="button" className="btn gv-btn" data-dismiss="modal" onClick={clear}>OK</button>
           </div>
          </div>
        </div>
      </div>
    </div>
     
  );
}

export default ErrorModal;
